import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { NextPage, GetStaticProps } from "next";
import type { SSRConfig } from "next-i18next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { Column, Grid, Row } from "@/components/grid";
import Layout from "@/components/layout";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import { FORBIDDEN_LOCALES, REVALIDATE } from "@/config";
import { Dirs, Locale } from "@/contentful/content-model/types";
import { layoutGateway } from "@/queries/gateways/layout";
import { pageGateway } from "@/queries/gateways/page";
import PageQuery from "@/queries/page.graphql";
import { Template } from "@/templates/page";
import type { PropsWithTheme } from "@/theme";
import { TypographyVariant } from "@/theme";
import type {
	BlogPost,
	BusinessInfoCollection,
	MetaCollection,
	NavigationCollection,
	Page,
	PageCollection,
	Person,
	SubPage,
	TextCollection,
} from "@/types/contentful-api";
import { buildMetaTags } from "@/utils/seo";

type EntryListCollection = Record<string, SubPage[] | BlogPost[] | Person[]>;

type NotFoundPageProps = SSRConfig & {
	data: {
		page: Page;
		entryListCollection: EntryListCollection;
		metaCollection: MetaCollection;
		textCollection: TextCollection;
		headerNavigation: NavigationCollection;
		footerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
	};
};

const Background = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	width: 100%;
	${({ theme: { palette, mq } }) => css`
		background-color: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
		@media ${mq.l} {
			background-color: ${palette.freeze[1000]};
			background-image: url("/assets/images/wave_visual.png");
			background-position: 100% 100%;
			background-repeat: no-repeat;
			margin-bottom: var(--spacing-xxxl);
		}
	`};
`;

// TODO: investigate amount of re-renders on page level
const NotFoundPage: NextPage<NotFoundPageProps> = ({ data }) => {
	const { t } = useTranslation("error-page");

	// Use fallback hardcoded 404 template in case no 404 contentful page is available
	if (!data.page) {
		return (
			<Layout data={data} meta={data.metaCollection.items[0]}>
				<Background>
					<Grid>
						<Row>
							<Column>
								<Spacer spacing="xxxl" />
								<Typography
									component="h1"
									variant={TypographyVariant.headlineSerif2XL}
								>
									{t("error-page:page-not-found")}
								</Typography>
								<Spacer spacing="xxxl" />
							</Column>
						</Row>
					</Grid>
				</Background>
			</Layout>
		);
	}

	return <Template data={data} />;
};

export const getStaticProps: GetStaticProps<NotFoundPageProps> = async ({ locale }) => {
	try {
		if (FORBIDDEN_LOCALES.includes(locale)) {
			throw new Error(`Page ${Dirs.notFound} not found`);
		}

		const translations = await serverSideTranslations(locale);

		const pageData = await pageGateway<Page, { pageCollection: PageCollection }>(PageQuery, {
			locale,
			dir: Dirs.notFound,
		});

		// Page not found in Contentful. We don't want to log this - just 404.
		if (!pageData) {
			return {
				notFound: true,
				revalidate: REVALIDATE,
			};
		}

		const layoutData = await layoutGateway({ locale });

		// MetaTags mapping
		pageData.page.meta = buildMetaTags({
			defaultMeta: layoutData.metaCollection.items[0],
			pageMeta: pageData.page.meta,
			titleOverride: pageData.page.headline,
		});

		return {
			props: {
				...translations,
				data: {
					...pageData,
					...layoutData,
				},
			},
			revalidate: REVALIDATE,
		};
	} catch (error: unknown) {
		if ((error as Error).message === `Page ${Dirs.notFound} not found`) {
			const fallbackLocale = Locale.de;
			const fallbackTranslations = await serverSideTranslations(fallbackLocale);

			const layoutData = await layoutGateway({ locale: fallbackLocale });

			return {
				props: {
					...fallbackTranslations,
					data: {
						page: null as Page,
						entryListCollection: null as EntryListCollection,
						...layoutData,
					},
				},
				revalidate: REVALIDATE,
			};
		}
	}
};

export default NotFoundPage;
